import React, { useRef } from 'react';
import { ArrowRight, BrainCircuit, Star, Users, 
  LinkedinIcon, TwitterIcon, InstagramIcon, CheckCircle, TrendingUp, 
  Clock, Shield } from 'lucide-react';

export default function LandingPage() {
  const detailsRef = useRef(null);

  const scrollToDetails = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    detailsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const testimonials = [
    {
      name: "João Silva",
      role: "Ex-Funcionário CLT",
      text: "Em 60 dias implementei o modelo de tutoria com IA e já estou faturando R$6.500/mês com apenas 4h de trabalho por dia.",
      result: "R$6.500/mês"
    },
    {
      name: "Maria Santos",
      role: "Empreendedora Digital",
      text: "O guia me ajudou a automatizar meu negócio usando IA. Reduzir 70% do tempo operacional e dupliquei meu faturamento.",
      result: "2x Faturamento"
    },
    {
      name: "Pedro Costa",
      role: "Iniciante em IA",
      text: "Comecei do zero, sem entender nada de IA. Em 45 dias montei minha agência de conteúdo e já tenho 3 clientes mensalistas.",
      result: "R$4.800/mês"
    }
  ];

  const features = [
    {
      icon: <BrainCircuit className="w-12 h-12 text-blue-400" />,
      title: "25 Modelos de Negócio",
      items: [
        "Criação de Conteúdo Digital",
        "Serviços de Escrita Avançada",
        "Produtos Digitais",
        "Educação e Treinamento",
        "Automação e Otimização"
      ]
    },
    {
      icon: <Clock className="w-12 h-12 text-blue-400" />,
      title: "Para Cada Negócio",
      items: [
        "Setup técnico detalhado",
        "Estratégia de marketing",
        "Precificação sugerida",
        "Processos automatizados",
        "Métricas de sucesso"
      ]
    },
    {
      icon: <TrendingUp className="w-12 h-12 text-blue-400" />,
      title: "Bônus Exclusivos",
      items: [
        "Templates prontos",
        "Prompts otimizados",
        "Acesso à comunidade VIP",
        "Mentorias mensais",
        "Atualizações gratuitas"
      ]
    }
  ];

  const stats = [
    { icon: <Users className="w-6 h-6" />, text: "500+ Alunos", subtext: "Resultados Comprovados" },
    { icon: <Star className="w-6 h-6" />, text: "4.9/5 Estrelas", subtext: "Avaliação Média" },
    { icon: <TrendingUp className="w-6 h-6" />, text: "R$5k-20k", subtext: "Potencial Mensal" },
    { icon: <Shield className="w-6 h-6" />, text: "Garantia 30 dias", subtext: "Risco Zero" }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-indigo-950 to-slate-900">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]" />

      {/* Hero Section - Ajustado */}
      <header className="relative container mx-auto px-4 pt-24 pb-16">
        <div className="text-center max-w-5xl mx-auto">
          {/* Badge de Lançamento */}
          <div className="inline-flex items-center bg-indigo-900/30 rounded-full px-6 py-2 text-indigo-200 mb-12">
            <span className="animate-pulse mr-2">🔥</span>
            <span>Lançamento 2024 - Edição Limitada</span>
          </div>

          {/* Título Principal - Espaçamento Ajustado */}
          <div className="space-y-8 mb-12">
            <h1 className="text-4xl md:text-7xl font-bold leading-tight">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-white via-blue-100 to-indigo-200">
                25 Negócios Digitais<br /> com IA
              </span>
            </h1>

            <h2 className="text-2xl md:text-3xl text-indigo-200 font-light">
              Guia Prático para Gerar Renda em 2025
            </h2>

            <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
              Do Zero ao Lucro: O Guia Definitivo para Construir Negócios Digitais Rentáveis
              Aproveitando o Poder da Inteligência Artificial
            </p>
          </div>

          {/* CTAs */}
          <div className="flex flex-col md:flex-row gap-4 justify-center items-center mb-16">
            <a href="#" className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:from-blue-700 hover:to-indigo-700 transition-all transform hover:scale-105 shadow-lg">
              Garantir Minha Cópia
              <ArrowRight className="w-5 h-5" />
            </a>
            <span className="text-gray-400">ou</span>
            <a 
              href="#detalhes" 
              onClick={scrollToDetails}
              className="text-indigo-300 hover:text-indigo-200 transition-colors underline"
            >
              Ver Detalhes do Livro
            </a>
          </div>

          {/* Stats Grid */}
          <div className="grid md:grid-cols-4 gap-6 max-w-4xl mx-auto">
            {stats.map((stat, index) => (
              <div key={index} className="flex flex-col items-center text-center p-4 bg-white/5 rounded-xl backdrop-blur-sm">
                {stat.icon}
                <strong className="text-white mt-2">{stat.text}</strong>
                <span className="text-gray-400 text-sm">{stat.subtext}</span>
              </div>
            ))}
          </div>
        </div>
      </header>

      {/* Features */}
      <section ref={detailsRef} className="py-20 bg-gradient-to-b from-slate-900 to-indigo-950">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-white mb-16">
            O Que Você Vai Encontrar No Livro
          </h2>

          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {features.map((feature, index) => (
              <div key={index} className="bg-gradient-to-br from-indigo-900/40 to-slate-900/40 p-8 rounded-2xl backdrop-blur-sm border border-indigo-800/20">
                <div className="mb-6">{feature.icon}</div>
                <h3 className="text-2xl font-bold text-white mb-6">{feature.title}</h3>
                <ul className="space-y-3">
                  {feature.items.map((item, i) => (
                    <li key={i} className="flex items-center gap-3 text-gray-300">
                      <CheckCircle className="w-5 h-5 text-green-400" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-20 bg-slate-900">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-white mb-16">
            Histórias de Sucesso
          </h2>

          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-gradient-to-br from-indigo-900/40 to-slate-900/40 p-8 rounded-2xl backdrop-blur-sm border border-indigo-800/20">
                <div className="flex text-yellow-400 mb-6">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-5 h-5 fill-current" />
                  ))}
                </div>
                <p className="text-gray-300 mb-6 text-lg">{testimonial.text}</p>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-semibold text-white">{testimonial.name}</p>
                    <p className="text-indigo-300 text-sm">{testimonial.role}</p>
                  </div>
                  <div className="bg-green-500/10 text-green-400 px-4 py-2 rounded-full text-sm font-medium">
                    {testimonial.result}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Author Section */}
      <section className="py-16 bg-gradient-to-b from-indigo-950 to-slate-900">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <div className="inline-flex items-center bg-blue-900/30 rounded-full px-6 py-2 text-blue-300 text-sm mb-4">
              <Star className="w-4 h-4 mr-2" />
              <span>Seu Mentor Nessa Jornada</span>
            </div>
            <h2 className="text-4xl font-bold text-white">
              Conheça Seu Guia no Mundo dos Negócios Digitais
            </h2>
          </div>

          <div className="max-w-5xl mx-auto">
            <div className="grid lg:grid-cols-12 gap-8 items-center">
              {/* Coluna da Imagem - Reduzida */}
              <div className="lg:col-span-4">
                <div className="relative">
                  {/* Efeito de Gradiente de Fundo */}
                  <div className="absolute -inset-3 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-xl blur-lg opacity-30 animate-pulse"></div>
                  
                  {/* Container da Imagem - Ajustado */}
                  <div className="relative aspect-[3/4] rounded-xl overflow-hidden">
                    <img 
                      src="https://i.pravatar.cc/533" 
                      alt="Carlos Balsalobre"
                      className="object-cover w-full h-full rounded-xl shadow-2xl"
                    />
                  </div>

                  {/* Badge flutuante - Redimensionado */}
                  <div className="absolute -right-4 top-8 bg-gradient-to-r from-blue-600 to-indigo-600 px-4 py-2 rounded-full shadow-xl">
                    <p className="text-white text-sm font-semibold">+500 Alunos</p>
                  </div>
                </div>
              </div>

              {/* Coluna do Conteúdo - Expandida */}
              <div className="lg:col-span-8">
                <div className="space-y-6">
                  <div>
                    <h3 className="text-2xl font-bold text-white mb-2">
                      Carlos Balsalobre
                    </h3>
                    <p className="text-blue-300 text-base font-medium">
                      Especialista em Empreendedorismo Digital & Inteligência Artificial
                    </p>
                  </div>

                  <div className="space-y-4 text-gray-300 text-base">
                    <p>
                      Com mais de uma década de experiência transformando ideias inovadoras em negócios lucrativos, sou pioneiro na aplicação de IA em negócios digitais. Desenvolvi metodologias próprias que permitem a qualquer pessoa criar fontes de renda consistentes utilizando as mais recentes tecnologias de Inteligência Artificial.
                    </p>
                    <p>
                      Como fundador de múltiplas empresas de tecnologia e mentor de centenas de empreendedores digitais, identifiquei e testei dezenas de modelos de negócio utilizando IA.
                    </p>
                  </div>

                  {/* Credenciais - Layout mais compacto */}
                  <div className="grid grid-cols-4 gap-4">
                    {[
                      { number: "10+", label: "Anos de Experiência" },
                      { number: "500+", label: "Alunos" },
                      { number: "25", label: "Modelos Validados" },
                      { number: "7+", label: "Empresas" }
                    ].map((stat, index) => (
                      <div key={index} className="bg-white/5 rounded-lg p-3 text-center backdrop-blur-sm">
                        <div className="text-xl font-bold text-blue-400">{stat.number}</div>
                        <div className="text-xs text-gray-400">{stat.label}</div>
                      </div>
                    ))}
                  </div>

                  {/* Social Links e CTA - Mais compacto */}
                  <div className="flex flex-wrap items-center gap-4">
                    <div className="flex gap-3">
                      <a href="#" className="text-gray-400 hover:text-blue-400 transition-all transform hover:scale-110">
                        <LinkedinIcon className="w-5 h-5" />
                      </a>
                      <a href="#" className="text-gray-400 hover:text-blue-400 transition-all transform hover:scale-110">
                        <TwitterIcon className="w-5 h-5" />
                      </a>
                      <a href="#" className="text-gray-400 hover:text-blue-400 transition-all transform hover:scale-110">
                        <InstagramIcon className="w-5 h-5" />
                      </a>
                    </div>
                    <div className="h-6 w-px bg-gray-800"></div>
                    <a href="#" className="inline-flex items-center gap-2 bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-full text-sm font-medium transition-all">
                      Ver Perfil Completo
                      <ArrowRight className="w-4 h-4" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-20 bg-gradient-to-b from-slate-900 to-indigo-950">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold text-white mb-8">
            Comece Sua Jornada Hoje
          </h2>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Garanta seu exemplar por apenas R$ 97,00 e comece sua jornada para a liberdade financeira com IA
          </p>
          <a href="#" className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:from-blue-700 hover:to-indigo-700 transition-all transform hover:scale-105 shadow-lg">
            Garantir Minha Cópia
            <ArrowRight className="w-5 h-5" />
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-slate-900 text-gray-400 py-8 text-center text-sm border-t border-gray-800">
        <div className="container mx-auto px-4">
          <p>© 2024 Carlos Balsalobre. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
}